<template>
  <div>
    <li class="nav-item d-none d-lg-block">
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0 btn btn-flat-dark dropdown-toggle waves-effect"
      >
        <template #button-content>
          <span
            class="dis-ib"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Quicks links"
          >
            <i
              data-feather="bell"
            />
            <span
              v-if="pendings"
              class="badge rounded-pill bg-danger badge-up"
            />
          </span>
        </template>
        <b-dropdown-item @click="$store.dispatch('modals/toggleCreateItem', true)">
          <i data-feather="plus" />
          New alert
        </b-dropdown-item>
        <div class="dropdown-divider" />
        <b-dropdown-item @click="$router.push({ name: 'alerts' })">
          <i data-feather="bell" />
          Alerts
        </b-dropdown-item>
        <b-dropdown-item
          :href="$router.resolve({name: 'researcher.dashboard'}).href + '#tasks'"
          target="_blank"
        >
          <i data-feather="toggle-left" />
          Tasks
        </b-dropdown-item>
      </b-dropdown>
    </li>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      pendings: 'alerts/pendings',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    this.$store.dispatch('alerts/fetchPendingToRead')
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: 'dummy-key',
      cluster: 'mt1', // Optional for Pusher clusters
      forceTLS: true, // Enable TLS for secure WebSocket connections
      wsHost: 'devapi.icrea.cat', // Optional custom WebSocket host
      wssPort: 6001,
      wsPort: 6001, // Optional custom WebSocket port
      disableStats: true,
    })
    // Working in local
    // window.Echo = new Echo({
    //   broadcaster: 'pusher',
    //   key: 'dummy-key',
    //   cluster: 'mt1',
    //   wsHost: 'localhost', // Change in dev/prod
    //   wsPort: 6001,
    //   forceTLS: false,
    //   disableStats: true,
    // })

    // Listen for events
    window.Echo.channel(`alerts.${this.loggedUser.id}`) // Listen on the "alerts" channel
      .listen('AlertEvent', data => { // Listen for events of type "AlertEvent"
        // Handle received alert data
        this.$store.dispatch('alerts/newNotification')
        this.$toastr.info(data.message, 'New notification')
        // Update UI to display the alert
      })
  },
  methods: {
  },
}
</script>
