<template>
  <div>
    <div v-if="user.id">
      <Header />
      <ResearcherMenu v-if="user.roles_all.some(e => e.researcher === true) || user.roles.includes('super-admin')" />
      <UniversityMenu v-if="user.roles.includes('university') || user.roles.includes('cae')" />
      <AwardedMenu v-if="user.roles.includes('awarded')" />
      <OndeuevMenu v-if="user.roles.includes('ondeuev')" />
      <EvaluatorMenu v-if="user.roles_all.some(e => e.evaluator === true) && !user.roles_all.some(e => e.researcher === true)" />
      <Menu v-if="user.roles.includes('super-admin')" />
    </div>
    <router-view
      @toggleModal="toggleModal"
    />
    <delete-modal
      :name="name"
      :url="modalUrl"
      :show="showModal == 'delete'"
      :required-fetch="requiredFetch"
      @closeModal="closeModal"
    />
    <reorganize-after-delete />
    <OTP />
    <AddFavorite />
    <ReportModal />
    <CreateAlert v-if="$route.name !== 'researcher.dashboard'">
      <template #title>
        Create {{ !alert.created ? 'alarm' : 'event' }}
      </template>
      <template #create-alert>
        <div class="offcanvas-body">
          <div class="mb-1 offcanvas-body--view--title">
            <p class="mb-0">
              Date
            </p>
            <date-picker
              v-if="!alert.created"
              v-model="alert.begin_date"
              format="D MMM Y HH:mm:ss"
              class="w-100"
              type="datetime"
              value-type="format"
              :show-hour="true"
              :show-minute="true"
              :disabled-date="disabledRange"
            />
            <date-picker
              v-else
              v-model="alert.begin_date"
              format="YYYY-MM-DD HH:mm:ss"
              class="w-100"
              type="datetime"
              value-type="format"
              :show-hour="true"
              :show-minute="true"
              :disabled-date="disabledRange"
            />
          </div>
          <hr>
          <div
            v-if="user.roles.includes('super-admin')"
            class="mb-1"
          >
            <label
              for="areaseniorcall"
              class="form-label"
            >Users</label>
            <v-select
              v-model="alert.users"
              label="name"
              multiple
              :options="users"
              :get-option-key="option => option.id"
              placeholder="Type to search..."
              @search="onSearch({ name: $event, role: ['researcher'] }, 'users/fetchFakeUsers')"
            />
          </div>
          <div class="mb-1">
            <label
              for="areaseniorcall"
              class="form-label"
            >Title</label>
            <input
              v-model="alert.title"
              class="form-control"
              type="text"
            >
          </div>
          <div class="mb-1">
            <label
              for="areaseniorcall"
              class="form-label"
            >Message</label>
            <quill-editor
              v-model="alert.message"
            />
          </div>
          <div class="mb-1">
            <label
              for="areaseniorcall"
              class="form-label"
            >
              Attached file
            </label>
            <div
              class="input-group custom-file-button"
            >
              <label
                class="input-group-text"
                for="formFile"
              >Select file</label>
              <input
                id="formFile"
                :ref="`file-alarm`"
                class="form-control"
                type="file"
                @change="uploadFile('file-alarm')"
              >
            </div>
          </div>
          <div class="mb-1">
            <div class="form-check form-check-success form-switch d-flex align-items-center">
              <input
                id="sendemail"
                v-model="alert.send_mail"
                type="checkbox"
                value="View Archived"
                class="form-check-input"
              >
              <label
                class="form-check-label"
                for="sendemail"
              >
                <small class="ms-1">Send alert by mail too</small>
              </label>
            </div>
          </div>
          <div
            v-if="alert.created"
            class="mb-1"
          >
            <label
              for="areaseniorcall"
              class="form-label"
            >Type</label>
            <v-select
              v-model="alert.type"
              label="label"
              :options="[
                {label: 'Task', value: 1},
                {label: 'Alarm', value: 2},
              ]"
              :get-option-key="option => option.value"
            />
          </div>
        </div>
        <div class="offcanvas-footer mt-auto">
          <button
            v-if="!sending"
            type="button"
            class="btn btn-dark mb-1 d-grid w-100"
            @click="submit"
          >
            Create
          </button>
          <button
            v-if="sending"
            class="btn btn-dark mb-1 w-100"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Creating...</span>
          </button>
        </div>
      </template>
    </CreateAlert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateAlert from '@/views/back/partials/offcanvas/CreateAlert.vue'
import Header from '../../views/back/partials/Header.vue'
import Menu from '../../views/back/partials/Menu.vue'
import ResearcherMenu from '../../views/back/partials/ResearcherMenu.vue'
import UniversityMenu from '../../views/back/partials/UniversityMenu.vue'
import AwardedMenu from '../../views/back/partials/AwardedMenu.vue'
import EvaluatorMenu from '../../views/back/partials/EvaluatorMenu.vue'
import DeleteModal from '../../views/back/partials/modals/DeleteModal.vue'
import ReorganizeAfterDelete from '../../views/back/partials/modals/ReorganizeAfterDelete.vue'
import AddFavorite from '../../views/back/partials/offcanvas/AddFavorite.vue'
import OTP from '../../views/back/partials/modals/OTP.vue'
import ReportModal from '../../views/back/partials/modals/ReportModal.vue'
import OndeuevMenu from '../../views/back/partials/OndeuevMenu.vue'

// import core styles
require('@core/scss/core.scss')
require('@core/scss/base/pages/app-todo.scss')
require('@core/scss/vue/libs/vue-sweetalert.scss')

// import assets styles
require('@/assets/scss/components.scss')
require('@/assets/scss/style.scss')
require('@/assets/scss/vertical_menu.scss')
require('@/assets/scss/vue-select.scss')

export default {
  components: {
    Header,
    Menu,
    DeleteModal,
    ResearcherMenu,
    ReorganizeAfterDelete,
    OTP,
    EvaluatorMenu,
    AddFavorite,
    ReportModal,
    UniversityMenu,
    AwardedMenu,
    CreateAlert,
    OndeuevMenu,
  },
  data() {
    return {
      showModal: false,
      modalUrl: '',
      name: '',
      requiredFetch: true,
      customMessage: '',
      alert: {},
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      users: 'users/fakeUsers',
    }),
  },
  methods: {
    disabledRange(date) {
      return date <= new Date()
    },
    toggleModal(data) {
      this.showModal = data.type
      this.modalUrl = data.url
      this.name = data.name
      this.requiredFetch = data.requiredFetch
      this.customMessage = data.customMessage
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]

      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
        user_id: this.user.id,
      }

      this.alert.file = fileData
    },
    closeModal() {
      this.showModal = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async submit() {
      this.sending = true
      await this.$store.dispatch('alerts/create', this.alert)
      this.$store.dispatch('modals/toggleCreateItem', false)
      this.alert = {}
      this.sending = false
      this.$toastr.success('', 'New alert created')
    },
  },
}
</script>
