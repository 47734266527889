<template>
  <!-- BEGIN: Main Menu-->
  <div>
    <div
      class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-dark"
      :style="openMenu ? 'transform: translate3d(260px, 0, 0);width: 260px;left: -260px;' : ''"
      data-scroll-to-active="true"
    >
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item me-auto">
            <a
              class="navbar-brand"
              href="#"
            >
              <img
                :src="require('@/assets/images/logo/logo-icrea-white-menu.svg')"
                alt="ICREA"
              >
            </a>
          </li>
        </ul>
      </div>
      <div class="shadow-bottom" />
      <div class="main-menu-content overflow-auto mb-5">
        <ul
          id="main-menu-navigation"
          class="navigation navigation-main"
          data-menu="menu-navigation"
        >
          <li
            v-if="convoData.promotion && (convoData.promotion.assignPeers || convoData.promotion.evaluate)"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-4`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.promotion.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-4`">
              <ul class="menu-content">
                <li
                  v-if="convoData.promotion.assignPeers"
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations.assign-peers' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Assign peers</span><span class="ms-50 badge bg-danger">{{ convoData.promotion.days }} days</span>
                  </a>
                </li>
                <li
                  v-if="convoData.promotion.evaluate"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.promotion.evaluations' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.promotion.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="convoData.academia"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-5`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.academia.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-5`">
              <ul class="menu-content">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.academia.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="convoData.senior"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-senior`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.senior.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-senior`">
              <ul class="menu-content">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations.senior' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.senior.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item">
            <a
              class="d-flex align-items-center"
              @click="$router.push({ name: 'evaluator.calls.evaluations.profile' })"
            >
              <i data-feather="user" /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >Profile</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="sidenav-overlay"
      :class="openMenu ? 'show' : ''"
      style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
      @click="openMenu ? $store.dispatch('modals/toggleEvaluatorMenu', false) : ''"
    />
  </div>

  <!-- END: Main Menu-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      daysPromo: null,
      convoTitlePromo: null,
      assignPeersPromo: null,
      evaluatePromo: null,
      convoData: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      openMenu: 'modals/showEvaluatorMenu',
    }),
  },

  watch: {
    show() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
  async mounted() {
    // Convo data
    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/has-active-calls`).then(resp => {
      this.convoData = resp.data
    })
  },
}
</script>
