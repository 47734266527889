<template>
  <div>
    <div
      id="modalDeleteWithInput"
      class="modal fade modal-danger"
      tabindex="-1"
      aria-labelledby="modalDeleteWithInputTitle"
      aria-hidden="true"
      :class="showModal ? 'show' : ''"
      :style="{ display: showModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="modalDeleteWithInputTitle"
              class="modal-title"
            >
              Delete
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            />
          </div>
          <div class="modal-body">
            <h4>
              <template v-if="!customMessage">
                This action cannot be reversed</template>
              <template v-else>
                {{ customMessage }}</template>
            </h4>
            <div class="mb-1">
              <label
                class="form-label"
                for="inputDelete"
              >Type <strong>DELETE</strong> and press confirm</label>
              <input
                v-model="word"
                type="text"
                class="form-control"
                :class="{'is-invalid': invalid}"
                name="inputDelete"
              >
              <div class="invalid-feedback">
                You have not written correctly: DELETE
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex align-items-center justify-content-between">
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="acceptModal()"
            >
              Confirm
            </button>
            <button
              type="button"
              class="btn btn-flat-danger"
              data-bs-dismiss="modal"
              @click="closeModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="closeModal()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    // show: { type: Boolean, required: true },
    // url: { type: String, required: true },
    // name: { type: String, required: true },
    // requiredFetch: { type: Boolean, required: true, default: true },

  },
  data() {
    return {
      word: '',
      showModal: false,
      invalid: false,
      url: '',
      name: '',
      requiredFetch: '',
      requiredType: '',
      customMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      show: 'modals/deleteModal',
      deleteData: 'modals/deleteData',
    }),
  },
  watch: {
    show() {
      this.invalid = false
      this.word = ''
      this.toggleModal()
    },
    deleteData() {
      this.url = this.deleteData.url
      this.name = this.deleteData.name
      this.requiredFetch = this.deleteData.requiredFetch === undefined ? true : (this.deleteData.requiredFetch === null ? false : this.deleteData.requiredFetch)
      this.requiredType = this.deleteData.requiredType ?? false
      this.customMessage = this.deleteData.customMessage ?? false
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    closeModal() {
      this.$store.dispatch('modals/showDeleteModal', false)
    },
    async acceptModal() {
      if (this.word === 'DELETE') {
        await Vue.prototype.$http.delete(this.url)
        Vue.swal('Deleted successfully', '', 'success').then(async () => {
          if (this.requiredFetch) {
            if (this.requiredType !== false) {
              if (this.name.includes('/')) {
                await this.$store.dispatch(`${this.name}`, `${this.requiredType}`)
              } else {
                await this.$store.dispatch(`${this.name}/fetch`, `${this.requiredType}`)
              }
              this.closeModal()
            } else {
              if (Array.isArray(this.name)) {
                this.name.forEach(item => {
                  this.$store.dispatch(`${item}`)
                })
              } else if (this.name.includes('/')) {
                await this.$store.dispatch(`${this.name}`)
              } else {
                await this.$store.dispatch(`${this.name}/fetch`)
              }
              this.closeModal()
            }
          } else {
            this.$router.push({ name: `${this.name}` })
            this.closeModal()
          }
          feather.replace({
            width: 14,
            height: 14,
          })
        })
      } else {
        this.invalid = true
      }
    },
  },
}
</script>
